import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';


const routes: Routes =[
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)},
  { path: '', loadChildren: () => import('./container-layout/container-layout.module').then(m => m.ContainerLayoutModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]

})
export class AppRoutingModule {
  constructor(private router: Router){}
  Redirect(){
    this.router.navigate(['/announcement/list']);
  }
  RedirectNav(item : any){
    this.router.navigate([`/${item}/list`]);
  }
}
