import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { LoginResponse } from '../interfaces/login-response';
import { environment } from 'src/environments/environment';
const { apiUrl } = environment;

export interface ApplicationUser {
  role: string;
  access_token: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<ApplicationUser>;
  public currentUser: Observable<ApplicationUser>;
  private static readonly TOKEN_NAME: string = 'access_token';

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<ApplicationUser>(
      this.getUserFromToken()
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(user: any) {
    return this.http.post<any>(`${apiUrl}/auth/login`, user).pipe(
      map((res) => {
        if (res && res.token) {
          this.token = res.token;
          this.currentUserSubject.next(this.getUserFromToken());
          localStorage.setItem('access_token', res.token);
        }
        return res;
      })
    );
  }

  

  private decodeToken(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  }

  get token(): string | null {
    return localStorage.getItem('access_token');
  }

  set token(token: string | null) {
    token != null
      ? localStorage.setItem(AuthService.TOKEN_NAME, token)
      : localStorage.removeItem(AuthService.TOKEN_NAME);
  }

  getUserFromToken(): any | null {
    return this.token ? this.decodeToken(this.token).sub : null;
  }

  logOut() {
    localStorage.removeItem('access_token');
    this.token = null;
    this.currentUserSubject.next(null);
  }
}
