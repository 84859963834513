import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private authService: AuthService) {
}
  canActivate() {
    const currentUserValue = this.authService.currentUserValue;
    if (currentUserValue) {
        return true;
    }
    this.authService.logOut();
    this.router.navigate(['/login']);
    return false;
}
}
